import { render, staticRenderFns } from "./UserProfileEdit.vue?vue&type=template&id=6018ccfb&scoped=true"
import script from "./UserProfileEdit.vue?vue&type=script&lang=js"
export * from "./UserProfileEdit.vue?vue&type=script&lang=js"
import style0 from "./UserProfileEdit.vue?vue&type=style&index=0&id=6018ccfb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6018ccfb",
  null
  
)

export default component.exports