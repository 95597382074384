<template>
    <b-container class="version-info-container">
        <div class="version-desc">
            backend ver. {{backendVersion}}
        </div>
        <div class="version-desc">
            frontend ver. {{frontendVersion}}
        </div>
    </b-container>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "AppInfo",
        props: {
            showForRoles: {
                type: Array,
                default: () => []
            }
        },
        mounted() {
            this.$store.dispatch('getVersions')
        },
        computed: {
            frontendVersion() {
                return this.getVersions().frontend;
            },
            backendVersion() {
                return this.getVersions().backend;
            }
        },
        methods: {
            ...mapGetters([
                "getVersions"
            ])
        }
    }
</script>

<style scoped>
    .version-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .version-desc {
        font-size: 12px;
        color: #7a7a7a;
    }
</style>