<template>
  <b-container class="pt-2">
    <div v-if="isAdmin" class="d-flex">
      <router-link to="/services/create" class="btn btn-primary mb-2">Создать сервис</router-link>
    </div>
    <b-table
        hover
        :items="getDeviceDataTable"
        :fields="fields"
        ref="device_table"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Сервисы отсутствуют</h6>
      </template>
      <template v-slot:cell(id)="row">
        {{ row.item.id }}
      </template>
      <template v-slot:cell(name)="row">
        {{ row.item.name }}
      </template>
      <template v-slot:cell(description)="row">
        {{ row.item.description }}
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button class="" variant="primary"
                  :title="isAdmin ? 'Редактирование' : 'Просмотр'"
                  @click="prepareDetailPage(row.item.id)">
          <b-icon-pencil v-if="isAdmin" font-scale="0.75"></b-icon-pencil>
          <b-icon-eye v-else font-scale="0.75"></b-icon-eye>
        </b-button>
        <b-button class="ml-1" variant="primary"
                  v-if="isAdmin"
                  title="Удалить"
                  @click="deleteService(row.item.id)">
          <b-icon-trash font-scale="0.75"></b-icon-trash>
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>
<script>
import rolesApi from '@/modules/roles-service';
import {mapGetters} from "vuex";

export default {
  name: 'device-list',
  created() {
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      fields: [
        {
          key: 'id', label: 'Идентификатор'
        },
        {
          key: 'name', label: 'Название'
        },
        {
          key: 'description', label: 'Описание'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    getDeviceDataTable() {
      return rolesApi.getServices()
          .then(resp => {
            return resp.data;
          });
    },
    deleteService(id) {
      //запуск модального окна
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить сервис?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              rolesApi.deleteService(id)
                  .then(() => {
                    return this.$refs.device_table.refresh();
                  })
                  .catch(error => {
                    this.$bvToast.toast(error.response?.status === 403 ?
                        'Недостаточно прав на удаление' :
                        error.response?.status === 404 ?
                            'Сервис не найден' :
                            'Возникла неизвестная ошибка при удалении', {
                      title: 'Ошибка',
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                  });
            }
          });
    },
    prepareDetailPage(id) {
      this.$router.push(`/services/${id}`);
    }
  }
}
</script>
