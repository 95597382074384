<template>
  <b-container class="pt-2">
    <b-table
        hover
        :items="getFavorites"
        :fields="fields"
        :current-page="favoriteList.currentPage"
        ref="device_table"
        :per-page="favoriteList.perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Избранные отсутствуют</h6>
      </template>
      <template v-slot:table-busy>
        <loading-spinner/>
      </template>
      <template v-slot:cell(state)="row">
        {{ row.item.state == 'AVAILABLE' ? 'Доступен' : 'Недоступен' }}
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="favoriteList.perPage"
            class="text-light border-secondary bg-secondary">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="favoriteList.currentPage"
          class="my-0 ml-auto"
          :total-rows="favoriteList.totalCount"
          :per-page="favoriteList.perPage"
          aria-controls="template-table"
          @input="getFavorites">
      </b-pagination>
    </div>
  </b-container>
</template>
<script>
import favoriteService from "@/modules/favorite-service";

export default {
  name: 'user-favorite-list',
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      fields: [
        {
          key: 'id', label: 'ID', sortable: true
        },
        {
          key: 'name', label: 'Название товара', sortable: true
        },
        {
          key: 'price', label: 'Цена', sortable: true
        },
        {
          key: 'state', label: 'Наличие', sortable: true
        }
      ],
      favoriteList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      }
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    getFavorites(context) {
      return favoriteService.getFavoriteList(this.$route.params.id, context.perPage, context.currentPage, context.sortBy,
          context.sortDesc ? "DESC" : "ASC")
          .then(response => {
            this.favoriteList.totalCount = response.data.length;
            return response.data.productList;
          })
          .catch(error => {
            this.$bvToast.toast('Не удалось загрузить избранные пользователя' + (error.response == null ? '' : ` (код ${error.response.status})`), {
              title: 'Ошибка',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            });
          });
    }
  }
}
</script>
