import userApi from '@/modules/users-service';
export default {
    state: {
        user: null,
        versions: {
            backend: '-',
            frontend: '-'
        }
    },
    actions: {
        getVersions({commit}) {
            return userApi.getBackendVersion()
                .then(resp => commit("setVersions", {
                    backend: resp.data?.backend_version ?? '-',
                    frontend: process.env.VUE_APP_VERSION ?? '-'
                }))
                .catch(err => {
                    console.error('Ошибка при получении информации о версии', err);
                    commit("setVersions", {
                        backend: '-',
                        frontend: process.env.VUE_APP_VERSION ?? '-'
                    });
                })
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setVersions(state, versions) {
            state.versions = versions;
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        getVersions(state) {
            return state.versions;
        }
    }
}